import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-approve-user',
  templateUrl: './approve-user.component.html',
  styleUrls: ['./approve-user.component.css']
})
export class ApproveUserComponent extends BaseComponent implements OnInit {
  table: Array<any> = [];
  @ViewChild('applicationPopup') applicationPopup!: ElementRef;

  questionsList: Array<any> = []; audioList: any = {};
  questionsData: boolean = false;
  audioData: boolean = false;
  courses: Array<any> = [];
  shedules: Array<any> = [];
  userDetails: any;
  courseScheduleId: string = '';
  userId: any;
  workItemId: any;
  courseId: string = '';
  uploadRes: any;
  StatusForm!: FormGroup;
  rejectreason: any;
  selectedId: any; userObj: any;
  USERID: any;
  admissionStatus: any = '0';
  Verification: any = '0';
  verificationId: any;
  shortListing: any = '0';
  headingName: any = {};
  labelName: any = {};
  comments: string = '';
  constructor(CommonService: CommonService, toastr: ToastrService, private active: ActivatedRoute, private fb: FormBuilder) {
    super(CommonService, toastr);
    this.active.params.subscribe(res => {
      this.workItemId = res.id
    })
  }

  ngOnInit(): void {
    this.loadUsers();
    this.getLabels();
    this.loadHeadings();
    this.StatusForm = this.fb.group({
      APPLICATION_STATUS: ['',],
      // IS_APPROVED: ['',],
      COMMENTS: [''],
      VERIFICATION_STATUS: ['',]
    })

  }

  loadUsers() {
    this.table = [];
    this.CommonService.postCall('Registration/LoadUsersListByApprover', { "TenantCode": localStorage.getItem('TenantCode') }).subscribe((res: any) => {
      this.table = res;
    })
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }
  deactivateSpinner() {
    this.CommonService.deactivateSpinner();

  }

  getLabels() {
    this.labelName = {};
    this.CommonService.getCall(`TenantRegistration/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.labelName = res;
    })
  }


  loadHeadings() {
    this.headingName = {};
    this.CommonService.getCall(`EmsDynamicheadingLables/GetLabelValuesByTaskid/${this.workItemId}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {
      this.headingName = res;
    })
  }

  // reject(id, item) {
  //   this.selectedId = id;
  //   this.userObj = item;
  // }

  // ApproveUser(id: any, item) {
  //   this.activeSpinner();
  //   let payload = {
  //     "IS_APPROVED": 0,
  //     "CourseId": 0,
  //     "COMMENTS": '',
  //     "CREATEDBY": localStorage.getItem('UserId'),
  //     "ModifiedBy": localStorage.getItem('UserId'),
  //     "UserId": item.userid || item,
  //     "CourseScheduleId": 0,
  //     "TNT_CODE": localStorage.getItem('TenantCode'),
  //     "USER_NAME": item.name
  //   }

  //   if (id == 1) {
  //     const confirmed = window.confirm("Are you sure you want to approve?");
  //     if (confirmed) {
  //       payload.IS_APPROVED = 1;
  //       this.callApiAndUpdateUI(payload);

  //     }
  //   } else if (id == 2) {
  //     payload.IS_APPROVED = id;
  //     payload.COMMENTS = this.rejectreason;
  //     this.callApiAndUpdateUI(payload);
  //   } else {
  //     payload.IS_APPROVED = id;
  //     payload.COMMENTS = this.rejectreason;
  //     this.callApiAndUpdateUI(payload);
  //   }
  // }
  userData(item) {
    this.userObj = item;
    this.Verification = item?.verification_status_id;
    this.shortListing = item?.application_status_id;
    // this.admissionStatus = item?.is_approved_id;
  }
  callApiAndUpdateUI(form: any) {
    this.activeSpinner();
    let value = form.value;
    let payload = {
      // "IS_APPROVED": value.IS_APPROVED == "" ? 0 : value.IS_APPROVED,
      "CourseId": 0,
      "COMMENTS": value.COMMENTS,
      "APPLICATION_STATUS": value.APPLICATION_STATUS == "" ? 0 : value.APPLICATION_STATUS,
      "VERIFICATION_STATUS": (value.VERIFICATION_STATUS == "" ? 0 : value.VERIFICATION_STATUS),
      "IS_SUBMIT": value.VERIFICATION_STATUS == '3' ? 2 : 1,
      "CREATEDBY": localStorage.getItem('UserId'),
      "ModifiedBy": localStorage.getItem('UserId'),
      "UserId": this.userObj.userid,
      "CourseScheduleId": 0,
      "TNT_CODE": localStorage.getItem('TenantCode'),
      "ACTION_USERID": localStorage.getItem('UserId'),
      "USER_NAME": this.userObj.name
    }
    // if (payload.APPLICATION_STATUS == 2 && payload.IS_APPROVED == 1) {
    //   return this.toastr.warning("User Cannot be admitted before being shortlisted")
    // }
    if (this.Verification == '3' && this.comments =='') {
      this.toastr.warning("Comments is mandatory!");
      return this.deactivateSpinner();
    }
    this.CommonService.postCall('Registration/UpdateAproverStatus', payload).subscribe(
      (res: any) => {
        document.getElementById('Close')?.click();
        this.toastr.success("Submitted Successfully!");
        // payload.IS_APPROVED == 1 ? this.pay(2) : payload.IS_APPROVED == 2 ? this.pay(0) : ''
        this.loadUsers();
        this.close();
        this.deactivateSpinner();
      },
      (err: any) => {
        this.toastr.error(err.error ? err.error : 'Not Updated!');
        this.deactivateSpinner();
      }
    );
  }

  // pay(id) {
  //   let payload = {
  //     "APPL_PAYDET_USER_ID": localStorage.getItem('UserId'),
  //     "APPL_PAYDET_ISPAID": id
  //   }
  //   this.CommonService.postCall('Registration/UpdateApplicationFeeStatus', payload).subscribe((res: any) => {
  //     if (res.message == "true") {
  //       let payId: any = 2;
  //       localStorage.setItem('IsPaid', payId)
  //     }
  //   })
  // }
  download(url: any) {
    window.open(environment.videoUrl + url);
  }

  close() {
    // this.StatusForm.reset();
    // this.StatusForm
    this.Verification = '0';
    this.admissionStatus = '0';
    this.shortListing = '0';
    this.StatusForm.controls['COMMENTS'].setValue('');
  }

  loadQuestions(item: any, id: any) {
    if (id == 1) {
      this.CommonService.postCall('Registration/ViewUserAnsweredQuestions', { "USERID": item.userid }).subscribe((res: any) => {
        this.questionsList = res;
        this.questionsData = true;
        // this.audioData = false;

      })
    }


  }
  getDocuments(item) {
    this.CommonService.postCall('Registration/GetAudioPath', { "USERID": item.userid }).subscribe((res: any) => {
      this.uploadRes = res?.result;
      // this.viewFile(res.)  
    })
  }
  // viewFile(path:any){
  //   window.open('https://ouk.konza.go.ke' + path)
  // }

  // viewPaymentDetails(item){
  //   this.paymentDetails = item;
  // }
  userDetailsMethod(item: any) {
    this.USERID = item?.userid;
    this.getUserDetails();
  }
  getUserDetails() {

    this.CommonService.postCall('Registration/GetAudioPath', { "USERID": this.USERID }).subscribe((res: any) => {
      this.userDetails = res;
    })

  }
  downloadForm() {
    this.CommonService.activateSpinner();


    let payload = {
      "USERID": this.USERID,
      "file_name": "ApplicationForm.jrxml",
      "file_type": "pdf"
    };

    this.CommonService.reportAPi(payload)
      .subscribe((res: any) => {
        const urlSegments = res.data.viewPath.split('/');
        const fileName = urlSegments[urlSegments.length - 1];
        const a = document.createElement('a');
        a.href = environment.downloadReportUrl + fileName;
        a.download = 'Application Form.pdf'; // Specify the desired file name
        a.style.display = 'none'; // Hide the anchor element
        document.body.appendChild(a); // Append to the DOM
        a.click(); // Trigger the download
        document.body.removeChild(a);
        document.getElementById('ClosePopup')?.click(); // Remove the anchor element from the DOM
        this.deactivateSpinner();

      }, (err: any) => {
        this.deactivateSpinner();
      });
    this.deactivateSpinner();

    // var data: any = document.getElementById('printtable');
    // html2canvas(data).then((canvas: any) => {
    //   const imgWidth = 208;
    //   const pageHeight = 295;
    //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   let heightLeft = imgHeight;
    //   let position = 0;
    //   heightLeft -= pageHeight;
    //   const doc :any= new jspdf('p', 'mm');
    //   doc.text('', 10, 10); // Add the header.
    //   doc.text('', 10, doc.internal.pageSize.height - 10); // Add the footer.

    //   doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     doc.addPage();
    //     doc.text('', 10, 10); // Add the header.
    //     doc.text('', 10, doc.internal.pageSize.height - 10); // Add the footer.
    //     doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save('ApplicationForm.pdf');
    //   document.getElementById('ClosePopup')?.click();
    // });
    // this.deactivateSpinner();
  }
}
