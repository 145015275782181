import { UserIdleTimeOutService } from './../../services/user-idle-time-out.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private userIdleTimerService: UserIdleTimeOutService, private route: Router, private commonService: CommonService) {
    // let isLog=localStorage.getItem('isLog');
    // if(!isLog){
    //   localStorage.setItem('isLog',"1");
    //   location.reload()
    // }
    const url = window.location.href;

    const urlParts = url.split('/');
    const homeIndex = urlParts.indexOf('HOME');
    if (homeIndex !== -1 && homeIndex < urlParts.length - 2) {
      const extractedPath = urlParts.slice(homeIndex + 1, homeIndex + 3).join('/');      
      if (extractedPath == ('components/regForm'||'components/payment')) {}
      else if(extractedPath != 'HOME/dashboard'){
        // this.commonService.menuPermissions(extractedPath);
      }
    } else {
      console.log('Path not found in URL');
    }
    
    
    // const path = urlParts.slice(-2).join('/');
    // console.log(path);
    
    // if (path == 'components/regForm') {} else if (path != 'HOME/dashboard'){
    //   this.commonService.menuPermissions(path)
    // }
    // path != 'HOME/dashboard'? this.commonService.menuPermissions(path) : '';
    // this.commonService.menuPermissions(path);
  }

  ngOnInit(): void {
    window.scrollBy(0, 100);
    this.logout();
  }
  @HostListener('window:click')
  @HostListener('window:keydown')
  @HostListener('window:mousemove')
  refreshUserState() {
    clearTimeout(this.userIdleTimerService.userActiveTimer);
    this.userIdleTimerService.timeoutUserActivity();
  }
  logout() {
    this.commonService.activateSpinner();
    let value = localStorage.getItem('UserId');
    let roleId = localStorage.getItem('RoleId');
    let username = !localStorage.getItem('Username')
    if ((value == "null" || value == ("0" || 0 || '')) || (roleId == "null" || roleId == ("0" || 0 || '')) || username) {
      this.route.navigate(['/login']);
      this.commonService.deactivateSpinner();

    }
    this.commonService.deactivateSpinner();

  }


}
